class UserAuth {
    uid: string;
    username: string | null;

    constructor(uid: string, username: string | null) {
        this.uid = uid;
        this.username = username;
    }
}

export default UserAuth;
