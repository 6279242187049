import Config from './src/interfaces/Config';

const config: Config = {
  siteTitle: 'Toposware EEAP Site',
  twitterName: '@toposware',
  title: 'Toposware EEAP Site',
  rootUrl: '/', // used only for the social card atm, required (update when using other environment)
  protectedRoutePrefix: '/protected', // slash at the beginning, but not at the end. Also the page file name has to match.
  includeFolder: ['features-test', 'content', 'legal'],
  indexPath: '', // could be content/module-1/introduction.html for example or any path to a html page
  includeProtectedFolder: ['members'],
  protectedPagesPath: 'src/protected/pages',
  protectedLayoutsPath: 'src/protected/layout',
  pageTransitionDuration: 0.2,
  enableH3Toc: true,
  headerOffset: 100,
  docSearch: {
    appId: 'VVWNAJ5624',
    apiKey: '6d6707f74f5f526c74a579852fedffc5',
    indexName: 'Pages',
    placeholder: 'Search documentation',
  },
  navigation: [
    {
      label: 'Home',
      path: '/protected/members/introduction.html',
    },
    {
      label: 'Educate',
      content: [
        {
          label: 'Zero Knowledge Proofs',
          path: '/protected/members/zkpbasics.html',
        },
        {
          label: 'ZKP Examples',
          path: '/protected/members/zkp_examples.html',
        },
        {
          label: 'Topos Key Concepts',
          path: '/protected/members/key_concepts.html',
        },
        {
          label: 'The Topos Advantage',
          path: '/protected/members/topos_advantage.html',
        },
        {
          label: 'Topos Architecture',
          path: '/protected/members/topos_arch.html',
        },    
        {
          label: 'WIP: Zero Knowledge Use Cases',
          content: [
            {
              label: 'Authentication',
              path: '/protected/members/use_cases_authent.html',
            },
            {
              label: 'Identity Protection',
              path: '/protected/members/use_cases_idprotect.html',
            },
            {
              label: 'Anonymous Payments',
              path: '/protected/members/use_cases_payments.html',
            },
            {
              label: 'Verifiable Computation',
              path: '/protected/members/use_cases_verifcomp.html',
            },
            {
              label: 'On-Chain Voting',
              path: '/protected/members/use_cases_voting.html',
            },
            {
              label: 'Online Gambling',
              path: '/protected/members/use_cases_gambling.html',
            },
          ],
        },
        {
          label: 'disabled: Topos 101',
          path: '/protected/members/topos101.html',
        },
        {
          label: 'disabled: Upcoming Webinars',
          path: '/protected/members/upcomingwebinars.html',
        },
        {
          label: 'Glossary',
          path: '/protected/members/glossary.html',
        },
         {
          label: 'Resources',
          path: '/protected/members/presentations2.html',
        },
        {
          label: 'disabled: Certification',
          path: '/protected/members/certification.html',
        },
      ],
    },
        {
      label: 'Innovate',
      content: [
        {
          label: 'WIP: Native Interoperability',
          path: '#native-interop',
        },
        {
          label: 'WIP: Privacy and Security',
          path: '/protected/members/privacy_security.html',
        },
        {
          label: 'WIP: Scalability',
          path: '/protected/members/scalability.html',
        },
        {
          label: 'Topos Solutions:',
          content: [
            {
              label: 'zkAudit',
              path: '/protected/members/zkaudit.html',
            },
            {
              label: 'Supply Chain',
              path: '/protected/members/supply_chain.html',
            },
            {
              label: 'zk Machine Learning',
              path: '/protected/members/zkml.html',
            },
            {
              label: 'disabled: Cross-Asset Digital Ecosystem',
              path: '/protected/members/cadecosystem.html',
            },
            {
              label: 'disabled: RWA Verification',
              path: '/protected/members/rwaverification.html',
            },
          ],
        },
        {
          label: 'disabled: Business Consultation',
          path: '/protected/members/biz_consult.html',
        },
       {
          label: 'Topos Innovators:',
          content: [
            {
              label: 'Startup Ecosystem',
              path: '/protected/members/startup_ecosystem.html',
            },
            {
              label: 'Innovation Spotlight',
              path: '/protected/members/innovator_spotlight.html',
            },
          ],
        },
      ],
    },
    {
      label: 'Access',
      content: [
        {
          label: 'Solidity Developer Training',
          path: '/protected/members/dev_training.html',
        },
        {
          label: 'disabled: Toposware Permissioned Subnet',
          path: '/protected/members/perm_subnet.html',
        },
        {
          label: 'Testnet',
          path: '/protected/members/testnet.html',
        },
        {
          label: 'Subnet Development',
          path: '/protected/members/deploy_subnet.html',
        },
        {
          label: 'WIP: Documentation',
          path: '/protected/members/docs.html',
        },
      ],
    },

    {
      label: 'Accelerate',
      content: [
        {
          label: 'WIP: Expert Support',
          path: '/protected/members/support.html',
        },
        {
          label: 'disabled: Roadmap Consultation',
          path: '/protected/members/roadmap_consult.html',
        },
        {
          label: 'disabled: Networking',
          path: '/protected/members/networking.html',
        },
        {
          label: 'disabled: POC Support',
          path: '/protected/members/poc_support.html',
        },
        {
          label: 'Partners',
          path: '/protected/members/partners.html',
        },
      ],
    },
    {
      label: 'Engage',
      content: [
        {
          label: 'Contact Us & Office Hours',
          path: '/protected/members/contact_OH.html',
        },
        {
          label: 'Thought Leadership',
          path: '/protected/members/thought_leadership.html',
        },
        {
          label: 'Recommended Reading',
          path: '/protected/members/articles2.html',
        },
        {
          label: 'Social Media',
          path: '/protected/members/social_media.html',
        },
        {
          label: 'disabled: News and Multimedia',
          path: '#news-and-multimedia',
        },
        {
          label: 'disabled: Member Directory',
          path: '#member-director',
        },
        {
          label: 'disabled: Topos Directory',
          path: '#topos-directory',
        },
        {
          label: 'FAQ',
          path: '/protected/members/faq.html',
        },
      ],
    },
  ],
  footerLinks: [
    {
      label: 'Privacy Policy',
      path: '/legal/privacy-policy.html',
    },
  ],
  storage: {
    cookieConsentKey: 'cookieConsent',
  },
};

export default config;
