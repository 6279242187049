import React from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getFirebaseAuthService } from "../providers/firebaseProvider";
import { AuthContext } from "../utils/contexts";
import UserAuth from "../models/userAuth";
import { mapUserAuth } from "../utils/helpers";

const AuthProvider = ({ children }: any) => {

    const [currentUser, setCurrentUser] = React.useState<UserAuth | null>(null);
  
    React.useEffect(() => {
      const auth = getFirebaseAuthService();
      onAuthStateChanged(auth, (user) => setCurrentUser(mapUserAuth(user)));
    }, []);
  
    return (
      <AuthContext.Provider value={currentUser}>
        { children }
      </AuthContext.Provider>
    );
}
  
export default AuthProvider;
