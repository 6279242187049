import { User as FirebaseUser } from "firebase/auth";
import UserAuth from "../models/userAuth";

export const isBrowser = () => typeof window !== "undefined";

export const isTest = () => process.env.NODE_ENV === "test";

export const mapUserAuth = (user: FirebaseUser | null) => {
    let mappedUser = null;
    
    if (user) {
        mappedUser = new UserAuth(
            user.uid,
            user.email
        )
    }

    return mappedUser;
}
